<template>
  <SliderComponent :items="hot_links" :slider-options="SliderOptions" v-slot="{ item }" class="smi-slider">
    <a :href="item.link" target="_blank" class="smi-slide">
      <ImageComponent width="120" height="50" :head_img="item.img" />
      <span class="smi-slide__title">{{ item.title }}</span>
    </a>
  </SliderComponent>
</template>

<script>
import ImageComponent from "components/ImageComponent.vue";
import SliderComponent from "@/components/SliderComponent.vue";

export default {
  name: "SmiSlider",
  props: {
    hot_links: {
      type: Array,
      default: () => [],
    },
  },
  components: { SliderComponent, ImageComponent },
  data() {
    return {
      SliderOptions: {
        slidesPerView: 5,
        spaceBetween: 50,
        pagination: false,
        clickable: false,
        preloadImages: true,
        // lazy: true,
        // loop: true,
        speed: 3000,
        freeMode: true,
        autoplay: {
          delay: 1,
          enabled: true,
          disableOnInteraction: false,
        },
        breakpoints: {
          780: {
            spaceBetween: 80,
          },
        },
      },
    };
  },
};
</script>

<style lang="stylus">
.smi-slider {
  width 100%
  margin-top 30px
  //padding: 0 0 30px 100px;
  //+below(1400px) {
  //  padding: 50px 0 30px 80px;
  //}
  //+below(1100px) {
  //  padding: 0 0 30px 15px;
  //}

  & a {
    color var(--black)
  }

  & .slider__container {
    padding: 0
  }

  .swiper-wrapper {
    transition-timing-function: linear !important
  }

  & .swiper-slide {
    width auto
  }
}

.smi-slide {
  min-width 80px
  width 120px
  //width auto
  height 115px
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  transition var(--transition)
  cursor: pointer;

  +below(780px) {
    width: 80px
  }

  &__title {
    visibility hidden
    opacity: 0;
    text-align center
    font-size 0.825rem
    position absolute
    top 80%
    transition var(--transition)

    +below(780px) {
      top 75%
    }
  }

  &:hover {
    & .smi__slide-title {
      visibility visible
      opacity: 1
    }

    & img {
      filter none
    }
  }

  & img {
    object-fit contain
    max-height 80px
    width 100%
    filter grayscale(1)
    transition var(--transition)
    //max-width 150px

    +below(780px) {
      height 35px
    }
  }
}
</style>
